import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/login/auth.service';
import { Puesto } from '../interfaces/puesto';
import { Producto } from '../interfaces/producto';
import { AppsettingsComponent } from '../app-settings/appsettings.component'
/*
* Título                    : cat-prod-aut.service.ts
* Descripción               : Service para el apartado de Catálogo Puestos Autorizado
* Compañía                  : Transportes Castores de Baja California S.A. de C.V. – Área de Desarrollo
* Fecha de creación         : 2020-03-06
* Autor                     : Paulina Muñoz [Lavande94]
* Versión                   : 1.0.0
* ID Requerimiento/Ticket   : NULL
*/
@Injectable()
export class PuestoService {

  constructor(private appsettings: AppsettingsComponent, private authService: AuthService, private http: HttpClient, private router: Router) { }
  /*
	* Título                    : findPuestoAutorizadoByAll
	* Descripción               : Obtención de datos de puestos autorizados
	* Fecha de creación         : 2020-03-06
	* Autor                     : Paulina Muñoz [Lavande94]
	* Versión                   : 1.0.0
	* ID Requerimiento/Ticket   : NULL
	*/
  findPuestoAutorizadoByAll(): Observable<any> {
    return this.http.get<Puesto[]>(this.appsettings.API_ENDPOINT + 'tinterplaza/findPuestoAutorizadoByAll')
  }
  /*
	* Título                    : findPuestoByIdPuestoAndNombre
	* Descripción               : Obtención de puesto filtrado por el nombre
	* Fecha de creación         : 2020-03-06
	* Autor                     : Paulina Muñoz [Lavande94]
	* Versión                   : 1.0.0
	* ID Requerimiento/Ticket   : NULL
	*/
  findPuestoByIdPuestoAndNombre(nombre: string): Observable<any> {
    return this.http.get<Puesto[]>(this.appsettings.API_ENDPOINT + `personal/findPuestoByIdPuestoAndNombre/${nombre}`)
  }
  /*
	* Título                    : findProductoAutorizadoByIdEstatusProductoAutorizado
	* Descripción               : Obtención de todos los productos existentes para la asignación con el puesto
	* Fecha de creación         : 2020-03-06
	* Autor                     : Paulina Muñoz [Lavande94]
	* Versión                   : 1.0.0
	* ID Requerimiento/Ticket   : NULL
	*/
  findProductoAutorizadoByIdEstatusProductoAutorizado(): Observable<any> {
    return this.http.get<Producto[]>(this.appsettings.API_ENDPOINT + 'tinterplaza/findProductoAutorizadoByIdEstatusProductoAutorizado')
  }
  /*
	* Título                    : insertPuestoAutorizado
	* Descripción               : Obtención de datos de puesto autorizado
	* Fecha de creación         : 2020-03-06
	* Autor                     : Paulina Muñoz [Lavande94]
	* Versión                   : 1.0.0
	* ID Requerimiento/Ticket   : NULL
	*/
  insertPuestoAutorizado(producto: Producto, idUsuario:number, idPuesto:string): Observable<Puesto> {
     return this.http.post(this.appsettings.API_ENDPOINT + `tinterplaza/insertPuestoAutorizado/${idUsuario}/${idPuesto}`, producto).pipe(
      map((response: any) => response.puesto as Puesto),
      catchError(e => {
        // Badrequest validacion desde el backend
        if (e.status == 400) {
          return throwError(e);
        }
        if (e.error.mensaje) {
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    );
  }
  /*
	* Título                    : findByIdPuestoAutorizado
	* Descripción               : Obtención de datos de productos autorizados por ID
	* Fecha de creación         : 2020-03-06
	* Autor                     : Paulina Muñoz [Lavande94]
	* Versión                   : 1.0.0
	* ID Requerimiento/Ticket   : NULL
	*/
  findByIdPuestoAutorizado(id:number): Observable<any> {
    return this.http.get<Puesto>(`${this.appsettings.API_ENDPOINT}personal/findPuestoByIdPuesto/${id}`).pipe(
      catchError(e => {
        if (e.status != 401 && e.error.mensaje) {
          this.router.navigate(['/cat-puesto-aut']);
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    );
  }
  /*
	* Título                    : updatePuestoAutorizado
	* Descripción               : actualización de datos de un puesto autorizado
	* Fecha de creación         : 2020-03-06
	* Autor                     : Paulina Muñoz [Lavande94]
	* Versión                   : 1.0.0
	* ID Requerimiento/Ticket   : NULL
	*/
  updatePuestoAutorizado(producto: Producto, idUsuario:number, idPuesto:string): Observable<any> {

    return this.http.put(`${this.appsettings.API_ENDPOINT}tinterplaza/updatePuestoAutorizado/${idUsuario}/${idPuesto}`, producto).pipe(
      map((response: any) => response.puesto as Puesto),
      catchError(e => {
        // Badrequest validacion desde el backend
        if (e.status == 400) {
          return throwError(e);
        }
        if (e.error.mensaje) {
          console.error(e.error.mensaje);
        }
        console.error(e.error.mensaje);

        return throwError(e);
      })
    );
  }
  /*
  * Título                    : deletePuestoAutorizado
  * Descripción               : actualización del estatus de puesto autorizado
  *                             con el identificador 2 - Baja lógica
  *                             no se vuelve a mostrar esta informacion dentro de la tabla de la vista
  * Fecha de creación         : 2020-03-06
  * Autor                     : Paulina Muñoz [Lavande94]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  deletePuestoAutorizado(idPuesto:number): Observable<any> {
  let idUsuario=   this.authService.usuario.id;
    return this.http.delete(`${this.appsettings.API_ENDPOINT}tinterplaza/deletePuestoAutorizado/${idPuesto}/${idUsuario}`).pipe(
      catchError(e => {
        if (e.error.mensaje) {
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    );
  }
  /*
  * Título                    : findPuestoAutorizadoByNombrePuestoAndNombreDepartamento
  * Descripción               : Metodo de filtrado por nombre puesto y nombre departamento
  *
  * Fecha de creación         : 2020-03-06
  * Autor                     : Paulina Muñoz [Lavande94]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  */
  findPuestoAutorizadoByNombrePuestoAndNombreDepartamento(fNombre:string, fDepartamento:string): Observable<any> {
    return this.http.get<Puesto[]>(this.appsettings.API_ENDPOINT + `tinterplaza/findPuestoAutorizadoByNombrePuestoAndNombreDepartamento/${fNombre}/${fDepartamento}`)
  }

}
