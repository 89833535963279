import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ExcelSheet } from '../interfaces/nomina-estibadores/excel-sheet.interface';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

/*
* Título                    : excel.service.ts
* Descripción               : Servicio para la exportacion de tablas a Excel
* Compañía                  : Transportes Castores de Baja California S.A. de C.V. – Área de Desarrollo
* Fecha de creación         : 2020-06-26
* Autor                     : German Galaviz [germagafra]
* Versión                   : 1.0.0
* ID Requerimiento/Ticket   : NULL
*/
@Injectable()
export class ExcelService {

  constructor( public snackBar: MatSnackBar) { }
  /*
	* Título                    : exportaArchivoExcel
	* Descripción               : Exporta el json proporcionado a un xlsx
  * Fecha de creación         : 2020-06-26
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  * @param any[]: jsonData        ->  Arreglo de Datos para exportar
  * @param string: excelFileName  ->  Nombre del Archivo para xlsx;
	*/
  exportaArchivoExcel(jsonData: any[], excelFileName: string): void {
    if(jsonData != undefined && jsonData != null)
    {
      const hojaexcel: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
      const librotrabajo: XLSX.WorkBook = { Sheets: { 'data': hojaexcel }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(librotrabajo, { bookType: 'xlsx', type: 'array' });
      this.guardaArchivoExcel(excelBuffer, excelFileName);
    }
    else
    {
       this.openSnackBar('No existen datos disponibles para exportar', '⛔',1500);
    }

  }
  /*
  * Título                    : exportaArchivoExcelOptimizado
  * Descripción               : Exporta el json proporcionado a un xlsx con las columnas con un tamaño especifico
  * Fecha de creación         : 2022-10-13
  * Autor                     : JP Flores Zendejas
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  * @param any[]: jsonData        ->  Arreglo de Datos para exportar
  * @param string: excelFileName  ->  Nombre del Archivo para xlsx;
  */
  exportaArchivoExcelOptimizado(jsonData: any[], excelFileName: string): void {
    if (jsonData != undefined && jsonData != null) {
      const hojaexcel: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
      hojaexcel["!cols"] = [{ wch: 15 }, { wch: 13 }, { wch: 15 }, { wch: 25 }, { wch: 10 }, { wch: 15 }];
      const librotrabajo: XLSX.WorkBook = { Sheets: { 'data': hojaexcel }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(librotrabajo, { bookType: 'xlsx', type: 'array' });
      this.guardaArchivoExcel(excelBuffer, excelFileName);
    }
    else {
      this.openSnackBar('No existen datos disponibles para exportar', '⛔', 1500);
    }

  }
  /*
  * Título                    : guardaArchivoExcel
  * Descripción               : Guarda el archivo de excel con el nombre proporcionado
  * Fecha de creación         : 2020-06-26
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  * @param any: buffer            ->  Información para guardar en el archivo de xlsx
  * @param string: nombreArchiv
  * o  ->  Nombre del Archivo de xlsx
  */
  private guardaArchivoExcel(buffer: any, nombreArchivo: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, nombreArchivo + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
        this.openSnackBar('Descarga de XLSX Lista', '⟴',1500);
  }

  /*
  * Título                    : openSnackBar
  * Descripción               : Genera la notificacion con los parametros dados
  * Fecha de creación         : 2020-06-26
  * Autor                     : German Galaviz [germagafra]
  * Versión                   : 1.0.0
  * ID Requerimiento/Ticket   : NULL
  * @param string: message    ->  Mensaje a mostrar
  * @param string: action     ->  Icono
  * @param string: tiempo     ->  Cantidad de tiempo en milisegundos
  */
  openSnackBar(message: string, action: string, tiempo: number) {
    this.snackBar.open(message, action, {
      duration: tiempo
    });
  }

  exportaArchivoExcelHojas(dataExcel: ExcelSheet[], nombreArchivoExcel: string): void {
    if (dataExcel != undefined && dataExcel != null) {
      var libroTrabajo  = XLSX.utils.book_new();

      for (let hojaExcel  of dataExcel) {
        var hojaDatos  = XLSX.utils.json_to_sheet(hojaExcel.dataSheet);
        XLSX.utils.book_append_sheet(libroTrabajo , hojaDatos, hojaExcel.nameSheet);
      }

      const excelBuffer: any = XLSX.write(libroTrabajo , { bookType: 'xlsx', type: 'array' });
      this.guardaArchivoExcel(excelBuffer, nombreArchivoExcel);
    } else {
      this.openSnackBar('No existen datos disponibles para exportar', '⛔', 1500);
    }
  }
}
